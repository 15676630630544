import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Home = () => import('../views/Home.vue');
const About = () => import('../views/About.vue');
const Gallery = () => import('../views/Gallery.vue');
const Services = () => import('../views/Services.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/about',
        name: 'About Us',
        component: About,
      },
      {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
      },
      {
        path: '/services',
        name: 'Our Services',
        component: Services,
      },
    ],
  },
]


const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); 
  next();
});

export default router
